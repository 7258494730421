<template>
    <div class="pa-5">
        <v-overlay v-if="loading">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto">
            <v-card-title>
                <h2 v-t="'AccountsForm.EditingAccount'"></h2>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="$router.push({ name: 'Accounts' })" v-t="'General.Return'"></v-btn>
            </v-card-title>
            <v-form ref="form">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="currentAccount.username"
                                :label="$t('AccountsForm.AccountName')"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="password"
                                :label="$t('AccountsForm.AccountPassword') + (isEditMode ? $t('AccountsForm.LeaveEmpty') : '')"
                                required
                                type="password"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                outlined
                                multiple
                                chips
                                deletable-chips
                                v-model="currentAccount.roles"
                                :items="rolesOptions"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            <v-btn
                class="ma-5"
                color="primary"
                @click="addOrModifyAccount"
            >
                {{ isEditMode ? $t('General.Edit') : $t('General.Add') }}
            </v-btn>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'AccountsForm',

    data () {
        return {
            currentAccount: {
                id: '',
                name: '',
                roles: []
            },
            password: '',
            loading: false
        }
    },
    computed: {
        isEditMode () {
            return this.$route.params.id.indexOf('new') === -1
        },

        rolesOptions () {
            const userRoles = this.$store.getters['GET_ROLES']() || []
            const baseRoles = ['ROLE_USER', 'ROLE_ECOMMERCE']

            if (userRoles.includes('ROLE_SUPER_ADMIN')) {
                return [...baseRoles, 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
            }

            if (userRoles.includes('ROLE_ADMIN')) {
                return [...baseRoles, 'ROLE_ADMIN']
            }

            return baseRoles
        }
    },

    created () {
        if (this.isEditMode) {
            this.getCurrentAccount()
        }
    },

    methods: {
        getCurrentAccount () {
            this.$store.dispatch('getAccount', this.$route.params.id).then(() => {
                this.currentAccount = this.$store.getters['GET_CURRENT_ACCOUNT']()
                if (this.currentAccount === null) {
                    this.$emit('notify', {
                        color: 'red',
                        text: this.$t('AccountsForm.AccountNotFound')
                    })
                    this.$router.push({ name: 'Accounts' })
                }
            })
        },

        addOrModifyAccount () {
            this.loading = true
            this.$store.dispatch('getAllAccounts').then(() => {
                const accounts = this.$store.getters['GET_ACCOUNTS']()
                const duplicate = accounts.find(acc => acc.username === this.currentAccount.username && acc.id !== this.currentAccount.id)

                if (!duplicate) {
                    if (this.currentAccount.id) {
                        this.modifyAccount()
                    } else {
                        this.addAccount()
                    }
                } else {
                    this.loading = false
                    this.$emit('notify', {
                        color: 'red',
                        text: this.$t('AccountsForm.UsernameAlreadyUse')
                    })
                }
            })
        },

        addAccount () {
            this.currentAccount.password = this.password
            this.$store.dispatch('addAccount', this.currentAccount)
                .then(() => {
                    this.$router.push({ name: 'Accounts' })
                })
                .catch(err => {
                    this.loading = false
                    this.$emit('notify', {
                        color: 'red',
                        text: err.response?.data || err.message
                    })
                })
        },

        modifyAccount () {
            this.currentAccount.password = this.password || ''

            this.$store.dispatch('editAccount', this.currentAccount)
                .then(() => {
                    this.$router.push({ name: 'Accounts' })
                })
                .catch(err => {
                    this.loading = false
                    this.$emit('notify', {
                        color: 'red',
                        text: err.response?.data || err.message
                    })
                })
        }
    }
}
</script>
<style scoped>
</style>
